<script setup lang="ts">
import { NuxtLink } from "#components";
import routerOptions from "~/app/router.options";

interface SeoUrl {
  isCanonical: boolean;
  seoPathInfo: string;
  languageId: string;
}

interface ButtonConfig {
  url: string | null;
  type: "internal" | "external" | null;
  title: string;
  entity: string | null;
  newTab: boolean;
  entity_id: string | null;
  link?: {
    seoUrls?: SeoUrl[];
  };
}

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const route = useRoute();

const props = defineProps<{
  button: ButtonConfig;
  class: string;
  hasArrow?: Boolean;
  activeClass?: string;
}>();

const buttonConfig = props.button;
const btnClass = props.class;
const hasArrow = props.hasArrow;
const activeClass = props.activeClass;
const { languageIdChain } = useSessionContext();

const getButtonUrl = (config: ButtonConfig) => {
  if (config.type === "external" && config.url) {
    return config.url;
  }

  if (config.link?.seoUrls?.length) {
    const canonicalUrl = config.link.seoUrls.find(
      (url) =>
        url.isCanonical === true && url.languageId === languageIdChain.value
    );
    if (canonicalUrl) {
      return formatLink("/" + canonicalUrl.seoPathInfo);
    }
  }

  if (config.url) {
    return config.url;
  }

  return null;
};

const buttonUrl = computed(() => getButtonUrl(buttonConfig));

const isActiveLink = computed(() => buttonUrl.value === route.path);

const buttonClasses = computed(() => ({
  [btnClass]: true,
  [activeClass || ""]: isActiveLink.value,
}));
</script>

<template v-if="buttonConfig?.title">
  <NuxtLink
    v-if="buttonConfig && buttonUrl"
    :to="buttonUrl"
    :external="buttonConfig.type === 'external' ? true : false"
    :title="buttonConfig.title"
    :target="buttonConfig.newTab ? '_blank' : '_self'"
    :class="[
      buttonClasses,
      { 'inline-flex relative hover:pr-16 group overflow-hidden': hasArrow },
    ]"
  >
    <slot name="link" />
    <template v-if="hasArrow">
      <span
        class="ml-3 absolute right-6 opacity-0 group-hover:opacity-100 transition-all duration-300"
      >
        <img src="assets/icons/btn-arrow.svg" alt="arrow-right" />
      </span>
    </template>
  </NuxtLink>
  <template v-else> <slot name="link" /></template>
</template>
